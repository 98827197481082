const open_menu = document.getElementById("open-menu");
const close_menu = document.getElementById("close-menu");
if (open_menu) {
  open_menu.addEventListener("click", () => {
    document.querySelector(".header__nav--main").classList.add("open");
  });
  close_menu.addEventListener("click", () => {
    document.querySelector(".header__nav--main").classList.remove("open");
  });
}
lightGallery(document.getElementById("gallery-container"), {
  speed: 500,
  mode: "lg-fade",
  plugins: [lgVideo]
});
setTimeout(function () {
  document.getElementById("hero-text").classList.add("in");
}, 400);
function scrollDown() {
  var win_width = window.innerWidth;
  var top = window.scrollY;

  //Header animation
  const headerEl = document.getElementById("header");
  if (win_width > 600) {
    if (top > 80) {
      headerEl.classList.add("scroll");
      //headerLogo.setAttribute('src',logoData);
    } else {
      headerEl.classList.remove("scroll");
      //headerLogo.setAttribute('src',logoSrc);
    }
  }

  //Scroll animations
  var animatedEl = document.getElementsByClassName("animate-fade");
  var h = window.innerHeight;
  var i;
  for (i = 0; i < animatedEl.length; i++) {
    var viewportOffset = animatedEl[i].getBoundingClientRect();
    var topPos = viewportOffset.top;
    if (topPos <= h * 0.8) {
      animatedEl[i].classList.add("fade-in");
    }
  }

  // Lazy Load
  var lazy_image = document.querySelectorAll("[data-src]");
  if (lazy_image) {
    //console.log(lazy_image);
    for (var l = 0; l < lazy_image.length; l++) {
      var data_src = lazy_image[l].dataset.src;
      var viewportOffset = lazy_image[l].getBoundingClientRect();
      var topPos = viewportOffset.top;
      if (topPos <= h * 1.3) {
        lazy_image[l].setAttribute("src", data_src);
        lazy_image[l].removeAttribute("data-src");
        lazy_image[l].classList.add("loaded");
      }
    }
  }

  // Lazy Background
  var lazy_bg = document.querySelectorAll("[data-bg]");
  if (lazy_bg) {
    //console.log(lazy_bg);
    for (var l = 0; l < lazy_bg.length; l++) {
      var data_src = lazy_bg[l].dataset.bg;
      var viewportOffset = lazy_bg[l].getBoundingClientRect();
      var topPos = viewportOffset.top;
      if (topPos <= h * 1.3) {
        lazy_bg[l].setAttribute("style", "background-image:url(" + data_src + ")");
        lazy_bg[l].removeAttribute("data-bg");
        lazy_bg[l].classList.add("loaded");
      }
    }
  }
}
setTimeout(function () {
  document.querySelector("body").classList.add("loaded");
}, 800);
scrollDown();
window.addEventListener("scroll", function (e) {
  scrollDown();
});